.calendar-container {
  .spinner-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    height: 100% !important;
    background: white;
    opacity: 0.6;
  }
  .event-tooltip {
    background: #ccc;
    border-radius: 5px;
    font-size: 11px;
    padding: 2px 5px;
    position: absolute;
    z-index: 999;
    top: -25px;
    color: black;
    font-weight: 500;
  }
  .calendar-event__name a {
    text-decoration: none;
    color: inherit;
  }
}

.rbc-row-segment .rbc-event-content {
  overflow: visible;
}
.rbc-month-row {
  overflow: visible;
}
.rbc-event:hover {
  overflow: visible !important;
}
.rbc-event:hover .event-tooltip,
.rbc-event-content:hover .event-tooltip {
  visibility: visible;
  opacity: 1;
  display: block;
}
.rbc-agenda-view {
  .rbc-agenda-event-cell .event-tooltip {
    all: unset;
    font-size: 12px;
  }
}
.rbc-row {
  position: relative;
}
.rbc-month-row, .rbc-week-slot, .rbc-day-slot {
  .rbc-event {
    &--completed {
      background-color: $success;
    }
    &--acknowledged {
      background-color: $lightBlue !important;
    }
    &--rejected {
      background-color: $danger;
    }
  }
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 40px);
}
.rbc-day-slot .rbc-event {
  flex-flow: column !important;
  position: relative !important;
  .rbc-event-content {
    font-size: 12px;
  }
}
.bg-light-blue {
  background-color: $lightBlue !important;
}
.loading-resource {
  opacity: 0.7;
}
