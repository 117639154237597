// Color.
.hover\:text-white:hover {
    color: $white !important;
}

// Size.
.font-base {
    font-size: $font-size-base;
}

// Weight.
.font-weight-semibold {
    font-weight: 500 !important;
}