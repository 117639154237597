$form-floating-height:                  calc(3.5rem + #{$input-height-border}) !default;
$form-floating-line-height:             1.25 !default;
$form-floating-padding-x:               $input-padding-x !default;
$form-floating-padding-y:               1rem !default;
$form-floating-padding-y-font-sm:       1.15rem !default;
$form-floating-input-padding-t:         1.625rem !default;
$form-floating-input-padding-b:         .625rem !default;
$form-floating-label-height:            1.5em !default;
$form-floating-label-opacity:           .8 !default;
$form-floating-label-floated-opacity:   .8 !default;
$form-floating-label-transform:         scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-label-disabled-color:    $gray-600 !default;
$form-floating-transition:              opacity .1s ease-in-out, transform .1s ease-in-out !default;

.form-floating {
    position: relative;
  
    > .form-control,
    > .form-control-plaintext {
      height: $form-floating-height;
      min-height: $form-floating-height;
      line-height: $form-floating-line-height;
    }
  
    > label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%; // allow textareas
      max-width: 100%;
      padding: $form-floating-padding-y $form-floating-padding-x;
      opacity: $form-floating-label-opacity;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
      transform-origin: 0 0;
      @include transition($form-floating-transition);

      &.font-sm {
        padding: $form-floating-padding-y-font-sm $form-floating-padding-x;
      }
    }
  
    > .form-control,
    > .form-control-plaintext {
      padding: $form-floating-padding-y $form-floating-padding-x;
  
      &::placeholder {
        color: transparent;
      }
  
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
      // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
      &:-webkit-autofill {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
      }
    }
  
    > select.form-control {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  
    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-control-plaintext,
    > select.form-control {
      ~ label {
        opacity: $form-floating-label-floated-opacity;
        transform: $form-floating-label-transform;
  
        &::after {
          position: absolute;
          inset: $form-floating-padding-y ($form-floating-padding-x * .5);
          z-index: -1;
          height: $form-floating-label-height;
          content: "";
          @include border-radius($input-border-radius);
        }
      }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
      ~ label {
        color: rgba(var(--bs-body-color-rgb), #{$form-floating-label-floated-opacity});
        transform: $form-floating-label-transform;
      }
    }
  
    > .form-control-plaintext {
      ~ label {
        border-width: $input-border-width 0; // Required to properly position label text - as explained above
      }
    }
  
    > :disabled ~ label {
      color: $form-floating-label-disabled-color;
  
      &::after {
        background-color: $input-disabled-bg;
      }
    }
  }
  