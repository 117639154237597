.profile-picture-dropzone {
    min-height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}

.profile-picture-uploading-spinner-container {
    position: absolute;
    font-size: 0.875rem;
    color: black;
    top: 25px;
    left: calc((100% - 4em) / 2);
}

.document-uploading-spinner-container {
    position: absolute;
    font-size: 0.875rem;
    color: black;
    top: 0;
    left: 15px;
}

.profile-picture-current {
    max-height: 180px;
}
