.app-header {
	background-color: $primary;

	.nav-item {
		&:last-of-type {
			margin: 0 15px 0 0;
		}
	}

	.navbar-nav {
		.nav-link {
			color: white;
		}
	}
}
