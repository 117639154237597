.fill-primary {
    fill: $primary;
}

.fill-warning {
    fill: $warning;
}

.fill-alert-warning-color {
    fill: theme-color-level('warning', $alert-color-level);
}

.fill-gray-300 {
    fill: $gray-300;
}

.fill-gray-500 {
    fill: $gray-500;
}

.fill-white {
    fill: $white;
}

.fill-light {
    fill: $light;
}

.fill-none {
    fill: none;
}