.hover\:bg-primary:hover {
    background-color: $primary !important;
}

.bg-orange-gradient {
    background: $orange !important;
    background: $orangeGradient !important;
}

.hover\:bg-orange-gradient:hover {
    background: $orange !important;
    background: $orangeGradient !important;
}

.hover\:bg-opaque:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.group:hover .group-hover\:bg-primary {
    background-color: $primary !important;
}

.group:hover .group-hover\:bg-light {
    background-color: $light !important;
}

@include media-breakpoint-up(md) {
    .bg-md-transparent {
        background-color: transparent !important;
    }
    .bg-md-white {
        background-color: $white !important;
    }
    .hover\:bg-md-opaque:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
    }
    .odd\:bg-md-white:nth-child(odd) {
        background-color: $white !important;
    }
    .group:hover .group-hover\:bg-md-primary {
        background-color: $primary !important;
    }
}