.marquee {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // 1 index below the bootstrap overlay components so we don't
  // block dropdowns & more.
  // https://getbootstrap.com/docs/4.6/layout/overview/#z-index
  z-index: 999;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  p, h1, h2, h3, h4, h5, h6, pre {
    font-size: 1rem;
    margin-bottom: 0;
  }
}