.border-2 {
    border-width: 2px !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
    border-bottom-left-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
}

@include media-breakpoint-up(md) {
    .border-md {
        border: $border-width solid $border-color !important;
    }

    .border-md-2 {
        border-width: 2px !important;
    }

    .border-md-light {
        border-color: $light !important;
    }

    .border-md-danger {
        border-color: $danger !important;
    }

    .border-md-success {
        border-color: $success !important;
    }

    .border-md-left {
        border-left: $border-width solid $border-color !important;
    }

    .rounded-bottom-md-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}