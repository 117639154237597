.stroke-primary {
    stroke: $primary;
}

.stroke-warning {
    stroke: $warning;
}

.stroke-alert-warning-color {
    stroke: theme-color-level('warning', $alert-color-level);
}

.stroke-gray-300 {
    stroke: $gray-300;
}

.stroke-gray-500 {
    stroke: $gray-500;
}

.stroke-white {
    stroke: $white;
}

.stroke-light {
    stroke: $light;
}

.group:hover .group-hover\:stroke-white {
    stroke: $white;
}

@include media-breakpoint-up(md) {
    .group:hover .group-hover\:stroke-md-white {
        stroke: $white;
    }
}