/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}

.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;

    .rdtTimeToggle {
        text-align: center;
    }

    table {
        width: 100%;
        margin: 0;
    }

    td,
    th {
        text-align: center;
        height: 28px;
    }

    td {
        cursor: pointer;
    }

    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
        background: #eeeeee;
        cursor: pointer;
    }

    td.rdtOld,
    td.rdtNew {
        color: #999999;
    }

    td.rdtToday {
        position: relative;
    }

    td.rdtToday:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #428bca;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    td.rdtActive,
    td.rdtActive:hover {
        background-color: #428bca;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    td.rdtActive.rdtToday:before {
        border-bottom-color: #fff;
    }

    td.rdtDisabled,
    td.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    td span.rdtOld {
        color: #999999;
    }
    td span.rdtDisabled,
    td span.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    th {
        border-bottom: 1px solid #f9f9f9;
    }

    .dow {
        width: 14.2857%;
        border-bottom: none;
    }

    th.rdtSwitch {
        width: 100px;
    }

    th.rdtNext,
    th.rdtPrev {
        font-size: 21px;
        vertical-align: top;
    }

    th.rdtDisabled,
    th.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    thead tr:first-child th {
        cursor: pointer;
    }

    thead tr:first-child th:hover {
        background: #eeeeee;
    }

    tfoot {
        border-top: 1px solid #f9f9f9;
    }

    button {
        border: none;
        background: none;
        cursor: pointer;
    }

    button:hover {
        background-color: #eee;
    }

    thead button {
        width: 100%;
        height: 100%;
    }
}

.rdtOpen .rdtPicker {
    display: block;
}

.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
}

.rdtCounters {
    display: inline-block;
    > div {
        float: left;
    }
}

.rdtCounter {
    height: 100px;
    width: 40px;
    .rdtBtn {
        height: 40%;
        line-height: 40px;
        cursor: pointer;
        display: block;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;

        &:hover {
            background: #eee;
        }
    }

    .rdtCount {
        height: 20%;
        font-size: 1.2em;
    }
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;

    input {
        width: 100%;
        font-size: 1.2em;
        margin-top: 37px;
    }
}

.rdt {
    .form-control:read-only:not(:disabled) {
        background-color: white;
    }

    &.daysViewMode {
        .rdtPicker {
            top: -250px;
        }
    }

    &.monthsViewMode,
    &.yearsViewMode {
        .rdtPicker {
            top: -200px;
        }
    }
}
