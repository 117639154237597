@include media-breakpoint-up(sm) {
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .mw-sm-50 {
        max-width: 50% !important;
    }
}

@include media-breakpoint-up(md) {
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .mw-md-50 {
        max-width: 50% !important;
    }
}

@include media-breakpoint-up(xl) {
    .w-xl-40 {
        width: 40% !important;
    }
    
    .w-xl-60 {
        width: 60% !important;
    }
}