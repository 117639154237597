.sidebar {
	background-color: #1e222d;

	.nav {
		padding: 0.5rem;

		.nav-link {
			border-radius: 0.25rem;
			padding: 0.5rem 0.5rem;

			.nav-icon {
				color: $babyBlue;
			}

			&:hover {
				background: $orangeGradient;
				.nav-icon {
					color: $white;
				}
			}
		}

		.nav-dropdown {

			.nav-dropdown-items {

				.nav-item {

					a.nav-link {
						padding-left: 2rem;
						background: $primary;

						&.active {
							background: $lightBlue;
							.nav-icon {
								color: $white;
							}
						}
						&:hover {
							background: $lightBlue;
						}
					}
				}
			}
		}
	}
}
